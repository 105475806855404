/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.brafEditor {
  position: relative;
}
:global .w-e-text-container {
  background-color: #f6f6f6 !important;
}
:global .w-e-text-container .placeholder {
  display: none !important;
}
