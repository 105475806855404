/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.component {
  height: 100%;
  margin: 0 auto;
  width: 900px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.component > div {
  width: 100%;
}
.component .head {
  padding: 10px 20px;
  border-bottom: 1px solid #e4e4e4;
}
.component .text {
  display: flex;
  height: 120px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4e4e4;
}
.component .text .Image {
  width: 40px;
  height: 40px;
  margin: 0 30px;
}
.component .text .div {
  flex: 1;
}
.component .text Button {
  float: right;
}
.component .footer {
  padding: 10px 20px;
  height: 85px;
  background: #F2f2f2;
  color: #999999;
}
.component .footer h2 {
  color: #999999;
}
