/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.colorPicker .colorBolck {
  display: flex;
  align-items: center;
  width: 120px;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: hidden;
}
.colorPicker .colorText {
  flex: 1;
  font-size: 12px;
  text-align: center;
}
.colorPicker .colorBg {
  width: 32px;
  height: 100%;
}
:global .colorPicker_tooltip .ant-tooltip-inner {
  background: transparent;
  box-shadow: none;
}
:global .colorPicker_tooltip .ant-tooltip-arrow::before {
  background: none;
}
