/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.component {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #f0f2f5;
  background-image: url(./images/login_bg.svg);
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
}
.card {
  width: 450px;
}
.logo {
  height: 60px;
  margin: 0 auto;
}
.desc {
  text-align: center;
  margin-top: 12px;
  margin-bottom: 40px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
