/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.logWrapper {
  text-align: center;
}
.logo {
  height: 60px;
  transform: scale(0.6);
  transition: opacity 0.2s linear;
}
.logo_sm {
  width: 64px;
  height: 64px;
  transition: opacity 0.2s linear;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
}
.header_light {
  background-color: #ffffff;
}
.header_dark {
  color: #ffffff;
  background-color: #001529;
}
.content {
  padding: 16px;
}
